import React, { createContext, useState } from 'react'

export const CourseData = createContext()

function Context({children}) {
    const [selectedCourse, setSelectedCourse] = useState({})
  return (
    <CourseData.Provider value={{selectedCourse, setSelectedCourse}}>
        {children}
    </CourseData.Provider>
  )
}

export default Context