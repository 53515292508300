import React from 'react';

import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate()
  return (
    <>
      {/* Footer container */}
      <footer className="bg-gray-800 text-gray-300 lg:text-left lg:px-28">
        <div 
          className="flex items-center justify-center border-b border-gray-600 p-6 lg:justify-between"
          data-aos="fade-up"
        >
          <div className="mr-12 hidden lg:block">
            <span>Connect with us:</span>
          </div>
          {/* Social network icons container */}
          <div className="flex justify-center gap-4 text-lg">
            <a href="https://www.instagram.com/codingshaala/" target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="https://www.instagram.com/codingshaala/" target="_blank">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="https://www.linkedin.com/company/codingshaala/" target="_blank">
            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
           
           

            {/* Add other social icons similarly */}
          </div>
        </div>
        {/* Main container div */}
        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid-1 grid lg:gap-20 gap-10 md:grid-cols-2 lg:grid-cols-3">
            {/* About section */}
            <div data-aos="fade-up">
              <p>
              Coding Shaala is a dedicated training center designed to upskill aspiring software developers with hands-on learning in industry-leading technologies. Our courses focus on practical implementation, real-world projects, and corporate-ready training, ensuring that learners gain the expertise needed to excel in the IT industry.              </p>
            </div>
            {/* Links section */}
            <div data-aos="fade-up">
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start text-white">
                ABOUT US
              </h6>
              <p className="mb-4">
             


                <a onClick={() => navigate('/Aboutus')} className="text-gray-300 hover:text-white cursor-pointer">About Us</a>
              </p>
              <p className="mb-4">
                <a onClick={() => navigate('/course')} className="text-gray-300 hover:text-white cursor-pointer">Courses</a>
              </p>
              <p className="mb-4">
                <a  onClick={() => navigate('/contactus')} className="text-gray-300 hover:text-white cursor-pointer">Contact Us</a>
              </p>
              <p>
                <a href="#!" className="text-gray-300 hover:text-white">Assess Yourself</a>
              </p>
            </div>
            {/* Contact section */}
            <div data-aos="fade-up">
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start text-white">
                CONTACT US
              </h6>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5 text-gray-300"
                >
                  <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                  <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
                Behind C21 mall, PU-4, Plot no. 22, Vijay Nagar, Indore
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5 text-gray-300"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                +91 8878084604
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5 text-gray-300"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                +91 9630709988
              </p>
            </div>
          </div>
        </div>
        {/* Copyright section */}
        <div className="bg-gray-900 p-6  text-center text-gray-300">
          <span>© 2023 CodingShaala</span>
        </div>
      </footer>
      
    </>
  );
}

export default Footer;
