import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Benefits() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms for the animations
  }, []);


  return (
    <section className="bg-gray-100 py-12 px-6 lg:px-12 my-12">
    <div className="max-w-6xl mx-auto flex flex-col lg:flex-row  gap-12">
      
      {/* Left Section - Image */}
      <div className="w-full lg:w-1/2">
        <img
          src="https://media.istockphoto.com/id/1079587192/photo/mid-adult-professor-teaching-a-lecture-from-desktop-pc-at-computer-lab.jpg?s=612x612&w=0&k=20&c=5uZGvFA6Sr7MNZl7Y-YwndvNR3E5Fex94ggHAdofTq4=" // Replace with your image
          alt="About Coding Shaala"
          className="rounded-2xl shadow-lg w-full"
          data-aos="fade-up" // Add AOS animation
        />
      </div>

      {/* Right Section - Text Content */}
      <div   data-aos="fade-up" // Add AOS animation 
       className="w-full lg:w-1/2 text-center lg:text-left">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
          🚀 About Coding Shaala 
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          At <span className="font-semibold text-blue-600">Coding Shaala</span>, we are committed to shaping the next generation of software developers by providing 
          <strong> hands-on training</strong> in cutting-edge technologies. Our mission is to equip aspiring coders with the 
          <strong> skills, confidence, and experience</strong> needed to succeed in the IT industry.
        </p>
        <p className="text-lg text-gray-600 mb-6">
          We offer courses in <strong>MERN Stack, React.js, Node.js, Python, AI & Machine Learning, and Mobile App Development</strong>, focusing on 
          <strong> real-world projects, one-on-one mentorship, and corporate-ready training.</strong> Whether you're a beginner or an experienced professional, our structured 
          learning approach ensures you stay ahead in the competitive tech world.
        </p>
        
      </div>

    </div>
  </section>
  );
}

export default Benefits;
