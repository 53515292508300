import React, {useState} from 'react'

function ModuleList({list}) {
  const [modules, setModules] = useState([]);
  const [activeModule, setActiveModule] = useState(null);
  return (
    <div className=" p-4">
    <h1 className="text-3xl font-bold text-center mb-6">📚 FullStack MERN Course</h1>

    {/* Course Modules in List Format */}
    <div className="space-y-4">
      {list.map((module) => (
        <div key={module.id} className="border border-gray-300 rounded-lg p-4">
          <div
            className={`flex justify-between items-center cursor-pointer ${
              activeModule?.id === module.id ? "text-blue-600 font-semibold" : "text-gray-800"
            }`}
            onClick={() => setActiveModule(activeModule?.id === module.id ? null : module)}
          >
            <h2 className="text-lg">{module.name}</h2>
            <span className="text-gray-500">{activeModule?.id === module.id ? "▲" : "▼"}</span>
          </div>

          {/* Lesson List (Collapsible) */}
          {activeModule?.id === module.id && (
            <div className="mt-3 space-y-2">
              {module.lessons.map((lesson) => (
                <div key={lesson.id} className="p-3 bg-gray-100 rounded-md">
                  <h3 className="font-medium text-gray-900">{lesson.title}</h3>
                  <ul className="list-disc ml-5 text-gray-600">
                    {lesson.subtopics.map((subtopic, index) => (
                      <li key={index}>{subtopic}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
  )
}

export default ModuleList