import React from 'react'

function List({list}) {
  return (
    
<div class="flex  items-center justify-center  my-4  px-6">
  <div class="space-y-4 border-l-2 lg:w-[100%] border-dashed ">
    {list.map((i)=>
    <div class="relative w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
      </svg>
      <div class="ml-6">
        <h4 class="font-bold text-blue-500">{i.name}</h4>
        <p class="mt-2 max-w-screen-sm text-sm text-gray-800">{i.description}.</p>
      </div>
    </div>)}
  </div>
</div>
  )
}

export default List