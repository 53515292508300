import React, { useState } from 'react';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos'; // Import AOS
import { useEffect } from 'react';

function Feedback() {
  const [move, setMove] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS
  }, []);

  return (
    <div className='w-full flex gap-4 flex-col relative lg:items-start items-center mb-4 lg:mb-0' data-aos="fade-up">
      <div className='lg:flex hidden flex-row gap-8 absolute right-20 top-[10vh]'>
        <label 
          onClick={() => move > 0 ? setMove(move - 50) : setMove(50)} 
          className='text-[40px] cursor-pointer'
        >
          {"-"}
        </label>
        <label 
          onClick={() => move < 50 ? setMove(move + 50) : setMove(0)} 
          className='text-[40px] cursor-pointer'
        >
          {"+"}
        </label>
      </div>
      <div className='h-[4vh] lg:w-[15%] w-[50%] bg-[#EFEEFE] rounded-xl flex justify-center items-center'>
        <label className='text-[19px] text-center text-[#5751E1] font-semibold'>Our Testimonials</label>
      </div>
      <h1 className='lg:text-[38px] lg:text-start text-center text-[20px] font-semibold lg:w-[44%] w-full leading-snug'>
        What Students Think and Say About Coding Shaala
      </h1>
      <div className='lg:mt-[4%] w-full lg:h-[50vh] h-auto overflow-hidden'>
        <div 
          style={{ transform: `translateX(-${move}%)` }} 
          className='flex w-[200%] duration-500 flex-row'
        >
          <div className='w-[50%] flex lg:flex-row flex-col gap-10'>
            {/* Feedback 1 */}
            <div 
              className='flex drop-shadow-lg flex-col gap-5 lg:w-[33%] w-full bg-[#F1FDFF] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>AL & Machine Learning</h1>
              
              <p className='text-[gray] leading-6'>
              "The AI & Machine Learning course at Coding Shaala is incredible! The instructors are highly knowledgeable, and the curriculum is well-structured. I've gained hands-on experience with real-world applications, making me confident in my AI & ML skills."              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author1.png' alt='Student 1'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Sanket Choudhary</label>
              </div>
            </div>
            {/* Feedback 2 */}
            <div 
              className='flex drop-shadow-lg flex-col gap-5 lg:w-[33%] w-full bg-[#EDEAFF] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>MERN Stack Course</h1>
              
              <p className='text-[gray] leading-6'>
              "The MERN Stack course at Coding Shaala was exactly what I needed to level up my career. The hands-on projects were challenging, and the mentorship provided excellent guidance throughout my learning journey."              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author2.png' alt='Student 2'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Akshat Chokshey</label>
              </div>
            </div>
            {/* Feedback 3 */}
            <div 
              className='flex drop-shadow-lg flex-col gap-5 lg:w-[33%] w-full bg-[#FFF7E2] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>Backend Development</h1>
              
              <p className='text-[gray] leading-6'>
                "The Python Django Course was comprehensive and practical. I feel confident applying Django in real-world projects now."
              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author3.png' alt='Student 3'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Shubh Khatri</label>
              </div>
            </div>
          </div>
          {/* Additional Feedback */}
          <div className='w-[50%]  flex flex-row gap-10'>
            <div 
              className='flex flex-col drop-shadow-lg gap-5 w-[33%] bg-[#F1FDFF] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>Mobile App Development</h1>
              
              <p className='text-[gray] leading-6'>
              "The Mobile App Development course at Coding Shaala was both engaging and insightful. The hands-on approach allowed me to build real-world projects and gain a strong foundation in app development."              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author4.png' alt='Student 4'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Aditya Sharma</label>
              </div>
            </div>
            <div 
              className='flex flex-col drop-shadow-lg gap-5 w-[33%] bg-[#EDEAFF] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-bold text-[19px] text-[#5751E1]'>Backend Development</h1>
             
              <p className='text-[gray] leading-6'>
              "The Backend Development course with Node.js at Coding Shaala was a game-changer for me! The hands-on projects and expert guidance helped me understand APIs, databases, and server-side logic with ease."              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author5.png' alt='Student 5'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Palash Goud</label>
              </div>
            </div>
            <div 
              className='flex flex-col drop-shadow-lg gap-5 w-[33%] bg-[#FFF7E2] py-4 px-3  hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-bold text-[19px] text-[#5751E1]'>Data Analytics</h1>
             
              <p className='text-[gray] leading-6'>
                "The Data Analytics course equipped me with essential skills for analyzing data. The course content was relevant and up-to-date."
              </p>
              <div className='flex flex-row gap-3'>
                {/* <div className='h-[50px] w-[50px] rounded-full '>
                  <img className='rounded-full' src='https://example.com/author6.png' alt='Student 6'/>
                </div> */}
                <label className='relative top-2 text-[19px] font-semibold'>Trisha</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
