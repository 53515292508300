import React, {useState} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import List from "../Components/List";
import ModuleList from "../Components/ModuleList";

function Fullstack() {
  const selectedCourse = JSON.parse(localStorage.getItem('course'))
  
  const [activeTab, setActiveTab] = useState("overview");

  const handleTabClick = (activeTab) => {
    setActiveTab(activeTab);
  };
  return (
    <div>
      <Header />
      <div className="w-[100%] flex lg:flex-row flex-col justify-center items-center lg:mt-10 py-[30px]">
        <div className="w-[90%] lg:gap-[4%] gap-[30px] flex lg:flex-row flex-col">
          <div className="lg:w-[80%] w-full gap-[12px] flex flex-col">
            <img
              className="lg:h-[50vh] h-auto w-[100%]"
              src={selectedCourse.image}
            ></img>
           
            <div className="w-full rounded-lg  flex">
              <button 
              onClick={() => handleTabClick("overview")}
              className={`w-1/2 text-lg border font-semibold rounded-lg transition-all duration-300  
                ${
                  activeTab === "overview"
                    ? "button-43"
                    : "button-44"
                }`}
              role="button">Overview</button>
              <button 
              onClick={() => handleTabClick("courseContent")}
              className={`w-1/2 text-lg border font-semibold rounded-lg transition-all duration-300  
                ${
                  activeTab === "courseContent"
                    ? "button-43"
                    : "button-44"
                }`}
              role="button">Course Content</button>


              
            </div>
            {activeTab === "overview" && (
              <div>
                {" "}
                <label className="text-[26px] font-semibold">Description</label>
                <p className="w-full lg:leading-10 leading-8 lg:text-[22px] text-[20px] font-serif text-justify">
                  {selectedCourse.description}
                </p>
                <List list={selectedCourse?.technologies}></List>
              </div>
            )}
            {activeTab === "courseContent" && (
              <ModuleList list={selectedCourse?.courseModules}></ModuleList>
            )}
          </div>

          <div className="  lg:w-[50%] w-full items-center lg:h-[70vh] h-auto lg:py-0 py-4 flex flex-col border lg:gap-[2.5%] gap-[8px]">
            <h1 className="text-[black] font-semibold lg:text-[35px] m-0 text-center">
              Course{" "}
              <span className=" text-center text-[#323B8D] lg:text-[35px] font-semibold">
                Features
              </span>
            </h1>
            <div className="w-[100%] flex pt-4">
              <div className="w-[50%] flex pl-[6%]  items-center gap-4">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Group%20878.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">Duration</label>
              </div>
              <div className="  w-[50%] flex justify-end  font-semibold lg:text-[20px] pr-[6%]">
                {selectedCourse.courseFeatures.duration}
              </div>
            </div>
            <hr></hr>
            <div className="w-[100%] flex ">
              <div className="w-[60%]  flex pl-[6%]  items-center gap-4">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Icon%20awesome-language.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">Language</label>
              </div>
              <div className="  w-[40%]  flex justify-end pr-[6%] font-semibold lg:text-[20px]">
                Hindi/English
              </div>
            </div>
            <hr></hr>
            <div className="w-[100%] flex">
              <div className="w-[60%] pl-[6%] flex  items-center gap-4">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Icon%20awesome-certificate.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">
                  Certificate
                </label>
              </div>
              <div className=" pr-[6%] flex w-[40%] justify-end items-center font-semibold lg:text-[20px]">
                Yes
              </div>
            </div>
            <hr></hr>
            <div className="w-[100%] flex ">
              <div className="w-[60%] pl-[6%] flex  items-center gap-4 ">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Icon%20ionic-md-people.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">
                  One-On-One Training
                </label>
              </div>
              <div className=" flex pr-[6%] w-[40%] justify-end items-center font-semibold lg:text-[20px]">
                Yes
              </div>
            </div>
            <hr></hr>
            <div className="w-[100%] flex ">
              <div className="w-[90%] pl-[6%]  flex  gap-4">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Icon%20ionic-ios-time.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">
                  Flexible Timing
                </label>
              </div>
              <div className=" flex pr-[6%] w-[50%] justify-end  bg-[] items-center font-semibold lg:text-[20px]">
                Yes
              </div>
            </div>
            <hr></hr>
            <div className="w-[100%] flex ">
              <div className="w-[60%] pl-[6%] flex  items-center gap-4 bg-[]">
                <img
                  className="lg:h-[3vh] h-[2vh]"
                  src="https://codebetter.in/images/icons/Icon%20payment-cash.png"
                ></img>
                <label className="lg:text-[20px] font-semibold">
                  {" "}
                  Fee Installements{" "}
                </label>
              </div>
              <div className=" pr-[6%] flex justify-end w-[40%]  items-center font-semibold lg:text-[20px] ">
                Yes
              </div>
            </div>
            <hr></hr>

            <button className="w-[50%] rounded-md py-2 px-2 bg-[#00E1FE] lg:text-[22px] text-[black] font-semibold mt-[5%]">
              Reach Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Fullstack;
