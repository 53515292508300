import React, { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Map from '../Components/Map';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image from '../images/image1.jpg';
import image2 from '../images/contactlogo1.svg';
import image3 from '../images/contact-us-phone.svg';
import image4 from '../images/contact-us-timing.svg';

function Contactus() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className='w-full flex flex-col'>
      <Header />

      <div className='lg:h-[35vh] h-[20vh] relative w-full flex flex-col items-center justify-center'>
        <img className='h-full w-full brightness-50' src={image} alt='Background'></img>
        <div className='absolute w-[80%] flex flex-col items-center text-center'>
          <h1 className='lg:text-[50px] text-[20px] font-bold text-[white]' data-aos="fade-up">Contact Us</h1>
          <div className='flex flex-row gap-3 justify-center mt-2'>
            <label className='text-[18px] font-semibold text-[white]'>Home</label>
            <label className='lg:text-[18px] font-semibold text-[white]'>{'>'}</label>
            <label className='lg:text-[18px] font-semibold text-[white]'>Contact Us</label>
          </div>
        </div>
      </div>

      <div className='w-[100%] py-20 flex flex-col gap-5 items-center bg-[#E0F7FA]' data-aos="fade-up">
        <h1 className='text-[30px] font-bold text-[#00796B]'>Your One Click</h1>
        <label className='text-[28px] text-[#0097A7] text-center font-semibold'>
          Will connect your career to the bright FUTURE!
        </label>
        <div className='flex flex-col w-[100%] lg:gap-6 gap-20 items-center'>
          <label className='lg:w-[70%] w-full lg:px-0 px-3 text-center leading-loose'>
            <span className='text-[20px] font-semibold text-[#00796B]'>CodingShaala</span> Indore trainers and experts have many years of real-time work experience in their domain. Our trainer will teach in such an easy way that anyone can learn easily. The emphasis is placed on learning core techniques and principles to create a solid foundation. We concentrate on live projects with real examples. All these online courses are accompanied with live project implementation.
          </label>
          <div className='w-[80%] flex lg:flex-row flex-col lg:gap-6 gap-20 mt-4'>
            <div className=' lg:w-[33%] w-full px-3 text-center bg-white shadow-lg rounded-lg' data-aos="fade-up">
              <div className='h-[100px] w-[100px] rounded-full bg-[#B2DFDB] flex items-center justify-center relative top-[-5vh] mx-auto'>
                <img className='rounded-full' src={image2} alt="Office Address"></img>
              </div>
              <div className='flex flex-col gap-3 leading-8 mt-4'>
                <label className='text-[22px] font-semibold text-[#00796B]'>Office Address</label>
                <label className='text-[17px] text-[#004D40] hover:text-[#0097A7] duration-300'>Behind C-21 mall, PU-4, Plot no 22, Vijay Nagar, Indore</label>
              </div>
            </div>
            <div className=' lg:w-[33%] w-full px-3 text-center bg-white shadow-lg rounded-lg' data-aos="fade-up">
              <div className='h-[100px] w-[100px] rounded-full bg-[#B9FBC0] flex items-center justify-center relative top-[-5vh] mx-auto'>
                <img className='rounded-full' src={image3} alt="Contact Details"></img>
              </div>
              <div className='flex flex-col gap-3 mt-4'>
                <label className='text-[22px] font-semibold text-[#00796B]'>Contact Details</label>
                <div className='flex flex-col gap-2'>
                  <label className='text-[17px] text-[#004D40] hover:text-[#0097A7] duration-300'>Call On: +919630709988</label>
                  <label className='text-[17px] text-[#004D40] hover:text-[#0097A7]'>Email Us: codingshaala@gmail.com</label>
                </div>
              </div>
            </div>
            <div className=' lg:w-[33%] w-full px-3 text-center bg-white shadow-lg rounded-lg' data-aos="fade-up">
              <div className='h-[100px] w-[100px] rounded-full bg-[#D1C4E9] flex items-center justify-center relative top-[-5vh] mx-auto'>
                <img className='rounded-full' src={image4} alt="Office Timing"></img>
              </div>
              <div className='flex flex-col gap-3 mt-4'>
                <label className='text-[22px] font-semibold text-[#00796B]'>Office Timing</label>
                <div className='flex flex-col gap-2'>
                  <label className='text-[17px] text-[#004D40] hover:text-[#0097A7] duration-300'>Morning 10:00 AM to 09:00 PM</label>
                  <label className='text-[17px] text-[#004D40] hover:text-[#0097A7]'>Sunday Off</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex lg:flex-row flex-col w-full gap-10 px-[50px] py-[60px] bg-[#00796B]' data-aos="fade-up">
        <div className='lg:w-[50%] h-[50vh] w-full'>
          <Map />
        </div>
        <div className='lg:w-[50%] w-full flex flex-col py-5 gap-5 text-white'>
          <h1 className='text-[30px]'>Get in Touch</h1>
          <label className='text-[17px] leading-8 w-[80%]'>
            We read each e-mail and reply within a maximum of 2 business days. Please enter correct e-mail address so that we can get back to you.
          </label>
          <div className='w-full gap-4 flex flex-col lg:flex-row'>
            <input className='px-3 h-[50px] w-full lg:w-[48%] mb-4 lg:mb-0 rounded-lg border border-[#B2DFDB]' placeholder='Name'></input>
            <input className='px-3 h-[50px] w-full lg:w-[48%] mb-4 lg:mb-0 rounded-lg border border-[#B2DFDB]' placeholder='Email'></input>
          </div>
          <div className='w-full gap-4 flex flex-col lg:flex-row'>
            <input className='px-3 h-[50px] w-full lg:w-[48%] mb-4 lg:mb-0 rounded-lg border border-[#B2DFDB]' placeholder='Phone'></input>
            <select className='w-full lg:w-[48%] mb-4 lg:mb-0 px-3 rounded-lg border border-[#B2DFDB]'>
              <option>Select Course</option>
              <option>Web Development</option>
              <option>Python</option>
            </select>
          </div>
          <select className='w-full mb-4 px-3 h-[50px] rounded-lg border border-[#B2DFDB]' placeholder='How soon you want to join IT Training?'>
            <option>This week</option>
            <option>Upcoming week</option>
            <option>In a month</option>
          </select>
          <textarea className='w-full mb-4 px-3 h-[150px] rounded-lg border border-[#B2DFDB]' placeholder='Your Message'></textarea>
          <button className='mt-3 h-[50px] w-[210px] bg-[#005B56] text-[17px] text-[white] font-semibold rounded-3xl'>Send</button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contactus;
