import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "../Components/Header";
import Benefits from '../Components/Benefits';
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import Webdev from "../Components/Webdev";
import Feedback from "../Components/Feedback";
import Whylearnwithus from "../Components/Whylearnwithus";

function Home() {
    const navigate = useNavigate();
    
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
  
    return (
        <div>
            <Header></Header>
        
            <div className="lg:px-28 px-4">
            
                <div className="flex lg:flex-row lg:h-[100vh] flex-col lg:gap-20 gap-10 lg:items-center lg:my-0 my-10">
                    <div 
                        className="flex flex-col gap-[25px] lg:w-[45%] w-[100%]" 
                        data-aos="fade-right"
                    >
                        <h1 className="text-[40px] w-full font-bold ">
                           Empowering Future <span className="lg:text-[40px] text-[#1B8DD8]">Developers</span>  with Industry-Ready Training!
                           
                        </h1>
                        <label className="text-[20px] w-full font-semibold">
                        Learn the most in-demand technologies with hands-on projects and expert mentorship.
                        </label>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-4">
                                <div className="h-[25px] w-[25px] rounded-full bg-[#1B8DD8] flex justify-center items-center text-[17px] shadow-2xl font-bold">{">"}</div>
                                <label className="text-[20px] relative lg:top-[-2px] font-sans">100% Placements Assistance</label>
                            </div>
                            <div className="flex flex-row gap-4">
                                <div className="h-[25px] w-[25px] rounded-full bg-[#1B8DD8] flex justify-center items-center text-[17px] shadow-2xl font-bold">{">"}</div>
                                <label className="text-[20px] relative lg:top-[-2px] font-sans">One On One Training</label>
                            </div>
                            <div className="flex flex-row gap-4">
                                <div className="h-[25px] w-[25px] rounded-full bg-[#1B8DD8] flex justify-center items-center text-[17px] shadow-2xl font-bold">{">"}</div>
                                <label className="text-[20px] relative lg:top-[-2px] font-sans">Free 7-day trial</label>
                            </div>
                        </div>
                        <button onClick={()=> navigate('/course')} className="h-[7vh] hover:bg-[#1B8DD8] lg:w-[40%] w-[65%] duration-200 rounded-md mt-[4%] text-center font-semibold text-[white] bg-[#282568] hover:bg-[]">Explore All Course</button>
                    </div>     
                
                    <div 
                        className="border rounded-[20px] box border-blue-500  justify-center drop-shadow-lg flex flex-col px-10 py-10 gap-5 lg:w-[45%]"
                        data-aos="fade-left"
                    >
                        <label className="text-center text-[20px] font-bold">
                            Fill Up The Enquiry And Try Free Demo Sessions!
                        </label>
                        <label className="text-center text-[20px] font-bold">
                            Request a call back!
                        </label>
                        <input
                            className="bg-transparent border-b py-2 pl-2"
                            placeholder="Name*"
                        ></input>
                        <input
                            className="bg-transparent border-b py-2 pl-2"
                            placeholder="Email*"
                        ></input>
                        <input
                            className="bg-transparent border-b py-2 pl-2"
                            placeholder="Phone No*"
                        ></input>
                        <label>Select Courses</label>
                        <div className="flex flex-wrap gap-4 lg:text-[20px] mt-[-15px]">
                            <div className="flex gap-1 items-center">
                                <input
                                    style={{ width: 16, height: 16 }}
                                    className=""
                                    type="checkbox"
                                ></input>
                                <label>React Js</label>
                            </div>
                            <div className="flex gap-1 items-center">
                                <input style={{ width: 16, height: 16 }} type="checkbox"></input>
                                <label>Node Js</label>
                            </div>
                            <div className="flex gap-1 items-center">
                                <input style={{ width: 16, height: 16 }} type="checkbox"></input>
                                <label>Full Stack</label>
                            </div>
                            <div className="flex gap-1 items-center">
                                <input style={{ width: 16, height: 16 }} type="checkbox"></input>
                                <label>Python</label>
                            </div>
                        </div>
                        <button className="h-[7vh] hover:bg-[#1B8DD8] lg:w-[30%] w-[65%] duration-200 rounded-md mt-[4%] text-center font-semibold text-[white] bg-[#282568] hover:bg-[]">Register</button>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold font-sans lg:text-[20px]">Want To know More About Us?</label>
                            <label className="font-semibold font-sans lg:text-[20px]">Contact Us On: <span className="text-[#1B8DD8]">9630709988</span></label>
                        </div>
                    </div>
                </div>
            
                <Webdev data-aos="fade-up"></Webdev>
                <Whylearnwithus data-aos="fade-up"/>
                <Benefits data-aos="fade-up"></Benefits>
                <Feedback data-aos="fade-up"></Feedback>
        
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Home;
