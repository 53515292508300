import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Whylearnwithus() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="w-full py-12 bg-gray-800 flex flex-col items-center gap-6">
      <button
        className="h-12 lg:w-1/6 w-1/2 bg-gray-700 rounded-xl text-white font-sans font-semibold"
        data-aos="fade-up"
      >
        Why Learn With Us?
      </button>
      <label
        className="text-3xl font-bold text-white text-center"
        data-aos="fade-up"
      >
        Start your Learning Journey Today!
      </label>
      <label
        className="text-gray-400 lg:w-1/2 w-full text-lg font-sans text-center leading-8"
        data-aos="fade-up"
      >
        We started BEST IT Academy to provide high-quality Offline and Online
        corporate-level training for aspiring professionals.{" "}
      </label>
      <div className="flex lg:flex-row flex-col gap-6 w-full mt-8">
        <div
          className="flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6"
          data-aos="fade-up"
        >
          <img
            className="h-24 w-24"
            src="https://www.shutterstock.com/image-vector/business-expert-team-icon-executive-260nw-1833260176.jpg"
            alt="Learn with Experts"
          />
          <h1 className="font-semibold  text-xl text-white font-sans mt-2">
            {" "}
            Industry-Relevant Curriculum
          </h1>
          <label className="text-gray-400 text-center w-4/5 text-lg font-sans">
            Our courses are designed to meet current industry demands.
          </label>
        </div>
        <div
          className="flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6"
          data-aos="fade-up"
        >
          <img
            className="h-24 w-24"
            src="https://media.istockphoto.com/id/1317086782/vector/laptop-screen-with-play-icon-in-flat-style-play-sign.jpg?s=612x612&w=0&k=20&c=55hib6vmm2cmV3PQ3wDv12KPvoQR5AQvwO5U43iaics="
            alt="Offline and Online Trainings"
          />
          <h1 className="font-semibold text-xl text-white font-sans mt-2">
            Hands-on Learning
          </h1>
          <label className="text-gray-400 text-center w-4/5 text-lg font-sans">
            Gain practical experience with real-world projects and case studies.
          </label>
        </div>
        <div
          className="flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6"
          data-aos="fade-up"
        >
          <img
            className="h-24 w-24"
            src="https://e7.pngegg.com/pngimages/774/364/png-clipart-computer-icons-academic-certificate-flat-design-diploma-miscellaneous-text-thumbnail.png"
            alt="100% Job Assistance"
          />
          <h1 className="font-semibold text-xl text-white font-sans mt-2">
            Career Guidance & Support
          </h1>
          <label className="text-gray-400 text-center w-4/5 text-lg font-sans">
            Get resume-building tips, interview preparation, and job assistance.
          </label>
        </div>
        <div
          className="flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6"
          data-aos="fade-up"
        >
          <img
            className="h-24 w-24"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjfB2-PUXAmxNfukEDFM1U_0jCJITKnlh1lJLAw4wOMzcyxLoCX6XwDOxCvVl5Tu7X8_M&usqp=CAU"
            alt="Capstone Projects"
          />
          <h1 className="font-semibold text-xl text-white font-sans mt-2">
            One-on-One Training
          </h1>
          <label className="text-gray-400 text-center w-4/5 text-lg font-sans">
            Personalized mentoring for better understanding and faster learning.
          </label>
        </div>
      </div>
    </div>
  );
}

export default Whylearnwithus;
