import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
import Course from './Pages/Course'
import AccessYourself from './Pages/AccessYourself'
import Notes from './Pages/Notes'
import CSSNotes from './Pages/CSSNotes'
import Login from './Pages/Login'
import Subjects from './Pages/Subjects'
import Javascript from './Pages/Javascript'
import HTML from './Pages/HTML'
import CSS from './Pages/CSS'
import Contactus from './Pages/Contactus'
import Aboutus from './Pages/Aboutus'
import Node from './Pages/Node'
import Reactapp from './Pages/Reactapp'
import Python from './Pages/Python'
import Mobileappdev from './Pages/Mobileappdev'
import Frontend from './Pages/Frontend'
import Nextjs from './Pages/Nextjs'
import Fullstack from './Pages/Fullstack'
import Dataanalytics from './Pages/Dataanalytics'
import Context from './Context'
import ScrollToTop from './ScrollToTop'


function App() {
  return (
    <Context>

    <HashRouter>
    <ScrollToTop /> {/* This ensures scrolling to the top on route change */}

      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/course' element={<Course/>}></Route>
        <Route path='/access-yourself' element={<AccessYourself/>}></Route>
        <Route path='/notes' element={<Notes/>}></Route>
        <Route path='/css-notes' element={<CSSNotes/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/subjects' element={<Subjects/>}></Route>
        <Route path='/javascript' element={<Javascript/>}></Route>
        <Route path='/html' element={<HTML/>}></Route>
        <Route path='/notes/css' element={<CSS/>}></Route>
        <Route path='/Contactus' element={<Contactus/>}></Route>
        <Route path='/Aboutus' element={<Aboutus/>}></Route>
        <Route path='/Node' element={<Node/>}></Route>
        <Route path='/Reactapp' element={<Reactapp/>}></Route>
        <Route path='/Python' element={<Python/>}></Route>
        <Route path='/Frontend' element={<Frontend/>}></Route>
        <Route path='/mobileappdev' element={<Mobileappdev/>}></Route>
        <Route path='/Nextjs' element={<Nextjs/>}></Route>
        <Route path='/Fullstack' element={<Fullstack/>}></Route>
        <Route path='/Dataanalytics' element={<Dataanalytics/>}></Route>


      </Routes>
    </HashRouter>
    </Context>
  )
}

export default App
