import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Courses from '../json/Courses.json'
import { useContext } from 'react';
import { CourseData } from '../Context';

function Webdev() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  const courses = Courses

  const handleCourse = (course)=>{
    localStorage.setItem('course', JSON.stringify(course))
    navigate('/Fullstack')
  }

  return (
    <div className='flex flex-col gap-8 py-[30px]'>
      <h1 className='text-[40px] font-bold text-center'>
        Our Most Popular <span className='text-[#1B8DD8]'>Courses</span>
      </h1>
      <label className='text-center text-[20px] font-semibold'>
         Explore top short-term, job-oriented courses and certification programs designed for high-demand careers.
      </label>
      <div className='lg:flex lg:flex-wrap lg:gap-y-10 gap-y-4 hidden w-[100%] gap-[3.5%]'>
        {courses.map((course, index) => (
          <div
            key={index}
            onClick={() => handleCourse(course)}
            className=' lg:w-[31%] w-full cursor-pointer  border-2 '
            data-aos='fade-up'
          >
            <div>
              <img
                className='lg:h-[30vh] h-[26vh] w-full rounded-md object-cover'
                src={course.image}
                alt={course.title}
              />
            </div>
            <div className='flex flex-col gap-[10px] pl-[15px] py-[15px]'>
              <label className='text-[22px] font-bold font-sans cursor-pointer'>
                {course.title}
              </label>
            
              <label className='text-gray-700 text-[16px] cursor-pointer'>{course.description}</label>
            </div>
          </div>
        ))}
      </div>

      {/* Mobile Version */}
      <div className='lg:hidden flex flex-col w-[100%] gap-3'>
        {courses.map((course, index) => (
          <div
            key={index}
            onClick={() => handleCourse(course)}
            className='h-auto border-2 w-full cursor-pointer'
            data-aos='fade-up'
          >
            <div>
              <img
                className='lg:h-[30vh] h-[26vh] w-full rounded-md'
                src={course.image}
                alt={course.title}
              />
            </div>
            <div className='flex flex-col gap-[10px] pl-[15px] py-[15px]'>
              <label className='text-[20px] font-bold font-sans'>
                {course.title}
              </label>
            
              <label className='text-[gray] text-[16px]'>{course.description}</label>
             
             
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Webdev;
