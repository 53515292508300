import React, { useState, useEffect } from 'react';
import Logo from '../images/Logo.png';
import { useNavigate } from 'react-router-dom';
import logo from '../images/Logo.png';

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(true); // Default to true
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollingUp(currentScrollY < lastScrollY);
      setLastScrollY(currentScrollY <= 0 ? 0 : currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <div className={`w-full h-[10vh]  transition-transform duration-300`}>
      <div className={` w-full bg-white z-[10000] fixed top-0 left-0 right-0 border-b drop-shadow-md py-4 lg:px-20 px-4 h-[10vh] flex justify-between items-center`}>
        <img onClick={() => navigate('/')} className='lg:h-[7vh] h-[6vh] cursor-pointer' src={Logo} alt="Logo" />
        <div className='lg:flex hidden gap-10 text-[20px]'>
          <label className='cursor-pointer hover-3 font-bold' onClick={() => navigate('/')}>Home</label>
          <label className='cursor-pointer hover-3 font-bold' onClick={() => navigate('/contactus')}>Contact Us</label>
          <label className='cursor-pointer hover-3 font-bold' onClick={() => navigate('/course')}>Courses</label>
          <label className='cursor-pointer hover-3 font-bold' onClick={() => navigate('/Aboutus')}>About Us</label>
        </div>
        <div onClick={() => setSidebar(true)} className='lg:hidden block'>
          <i className="fa fa-bars text-[30px]" aria-hidden="true"></i>
        </div>
        {sidebar &&
          <div className={`lg:hidden flex flex-col h-[200vh] fixed top-0 left-0 p-4 pt-2 ${sidebar && "sidebar-animate"} duration-[1s] gap-5 text-[30px] bg-white shadow-lg drop-shadow-md z-[100] w-[70%]`}>
            <label className='text-right' onClick={() => setSidebar(false)}>x</label>
            <label className='font-semibold text-[24px]' onClick={() => navigate('/')}>Home</label>
            <label onClick={() => navigate('/contactus')} className='font-semibold text-[22px]'>Contact Us</label>
            <label className='font-semibold text-[22px]' onClick={() => navigate('/course')}>Courses</label>
            <label onClick={() => navigate('/aboutus')} className='font-semibold text-[22px]'>About Us</label>
            <div className='flex gap-3 mt-[2%]'>
              <div className='hover:bg-[#282568] duration-150 h-[5vh] w-[17%] border flex justify-center items-center'>
                <i className="text-[20px] text-[gray] fa fa-facebook hover:text-[white]" aria-hidden="true"></i>
              </div>
              <div className='hover:bg-[#282568] duration-150 h-[5vh] w-[17%] border flex justify-center items-center'>
                <i className="text-[20px] text-[gray] hover:text-[white] fa fa-instagram" aria-hidden="true"></i>
              </div>
              <div className='hover:bg-[#282568] duration-150 h-[5vh] w-[17%] border flex justify-center items-center'>
                <i className="text-[20px] text-[gray] fa fa-linkedin hover:text-[white]" aria-hidden="true"></i>
              </div>
            </div>
            <div className='w-full mt-[10%] flex'>
              <img className='w-[40%]' src={logo} alt="Logo" />
            </div>
          </div>}
      </div>
    </div>
  );
}

export default Header;
